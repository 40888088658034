<template>
    <div class="takeOutEleDetail2">
        <!-- <Header backType="blank" :backStyle="style" :svgColor="'#fff'" :back="!fromXBH"></Header> -->
        <!-- <Header backType="blank" :backStyle="style" :svgColor="'#fff'"></Header> -->
        
        <Header backType="white" :back="!fromXBH"></Header>
        <div class="merchantContent">
            <div class="detail_title">订单详情</div>
            <div class="merchantName">{{result.shopName}}</div>
            <div class="merchantMsg">
                <p @click="ihtml.telCall(result.shopMobile)">
                    <Svgs color="var(--themeColor)" name="icondianhua-"></Svgs>
                    {{result.shopMobile}}
                </p>
                <p>{{result.shopAddress}}</p>
            </div>
            <div class="merchantGroup">
                <div class="merchantGroupHead">需求明细</div>
                <ul class="merchantGroupList">
                    <div>
                        <span class="merchantGroupLabel">订单状态</span>
                        <span class="merchantGroupTxt">{{result.statusTxt}}</span>
                    </div>
                    <div v-if="result.dtStart">
                        <span class="merchantGroupLabel">用餐时间</span>
                        <span class="merchantGroupTxt">{{ result.dtStart | dateTime }}～{{ result | dateTime2 }}</span>
                    </div>
                    <div v-if="event.tenant_name != '会引擎-灵北'">
                        <span class="merchantGroupLabel">用餐类型</span>
                        <span class="merchantGroupTxt">{{result.itemName}}</span>
                    </div>
                    <div v-if="result.mealType">
                        <span class="merchantGroupLabel">用餐形式</span>
                        <span class="merchantGroupTxt">{{result.mealType}}</span>
                    </div>
                    <div v-if="result.actualQty">
                        <span class="merchantGroupLabel">实际人数</span>
                        <span class="merchantGroupTxt">{{result.actualQty}}</span>
                    </div>
                    <!-- <div v-if="['pfizer'].includes(event.tenant_code)">
                        <span class="merchantGroupLabel">外部用餐人数</span>
                        <span class="merchantGroupTxt">{{result.outPeople}}</span>
                    </div>
                    <div v-if="['pfizer'].includes(event.tenant_code)">
                        <span class="merchantGroupLabel">内部用餐人数</span>
                        <span class="merchantGroupTxt">{{result.innerPeople}}</span>
                    </div> -->
                    <div v-if="result.settleAmount">
                        <span class="merchantGroupLabel">实际金额</span>
                        <span class="merchantGroupTxt weight_bold">¥{{parseFloat(result.settleAmount).toFixed(2)}}</span>
                    </div>
                    <div v-if="orderDetail.amount && ['pfizer'].includes(event.tenant_code)">
                        <span class="merchantGroupLabel">预计金额</span>
                        <span class="merchantGroupTxt weight_bold">¥{{parseFloat(orderDetail.amount).toFixed(2)}}</span>
                    </div>
                    <div>
                        <span class="merchantGroupLabel">订餐人姓名</span>
                        <span class="merchantGroupTxt">{{result.bookingName}}</span>
                    </div>
                    <div>
                        <span class="merchantGroupLabel">订餐人电话</span>
                        <span class="merchantFont merchantGroupTxt">{{result.bookingMobile}}</span>
                    </div>
                    <div v-if="orderDetail.extOrderId && (((orderDetail.itsItemData || { payProfile:'' }).payProfile || {PayModes: []}).PayModes[0] == 8)">
                        <span class="merchantGroupLabel">饿了么订单号</span>
                        <span class="merchantGroupTxt">{{orderDetail.extOrderId}}</span>
                    </div>
                    <div v-if="orderDetail.itsItemData.isBusiness === 1">
                        <span class="merchantGroupLabel">友情提示</span>
                        <span class="merchantGroupTxt">餐厅确认收款，请告知其登录饿了么后台进行查看。</span>
                    </div>
                    <div>
                        <span class="merchantGroupLabel">备注</span>
                        <span class="merchantGroupTxt">{{result.descr}}</span>
                    </div>
                </ul>
            </div>
            <div class="merchantBtnRow">
                <div class="merchantGroupHead">水单</div>
                <viewer v-if="waterFilesList.length" :images="waterFilesList" class="imgContainer">
                    <template v-for="(list) in waterFilesList">
                        <div :key="list.url">
                            <img v-if="list.fileType != '.pdf'" :src="list.url" alt/>
                            <p  @click="openPdf(list.url)" v-else>
                                <i-icon style="width:.56rem;height:.56rem" class="icon-pdf2" color="var(--themeColor)" name="icon-pdf" />
                            </p>
                            <p v-if="list.relatedType == 'settle_supplement'">补传</p>
                        </div>
                    </template>
                </viewer>
                <div class="nodata" v-else>
                    暂无数据！
                </div>
            </div>
            <div class="merchantBtnRow">
                <div class="merchantGroupHead">发票</div>
                <viewer v-if="invoiceFilesList.length" :images="invoiceFilesList" class="imgContainer">
                    <template v-for="(list) in invoiceFilesList">
                        <div :key="list.url">
                            <p  @click="openPdf(list.url)" v-if="list.fileType == '.pdf'">
                                <i-icon style="width:.56rem;height:.56rem" class="icon-pdf2" color="var(--themeColor)" name="icon-pdf" />
                            </p>
                            <p  @click="openOfd(list.url)" v-else-if="list.fileType == '.ofd'">
                                <img src="../../assets/img/ofd.png" style="width:.56rem;height:.56rem"/>                             </p>
                            <img v-else :src="list.url" alt/>
                            <p v-if="list.relatedType == 'Invoice_supplement'">补传</p>
                        </div>
                    </template>
                </viewer>
                <div class="nodata" v-else>
                    暂无数据！
                </div>
            </div>
            <div class="merchantBtnRow" v-if="result.invoiceReason">
                <div class="merchantGroupHead">原因</div>
                <p class="invoiceReason">
                    {{result.invoiceReason}}
                </p>
            </div>
            <div class="merchantBtnRow" v-if="result.itemData && result.itemData.OwnerConfirm == 2">
                <div class="merchantGroupHead">拒绝原因</div>
                <p class="invoiceReason">
                    {{result.itemData.OwnerConfirmDescr}}
                </p>
            </div>
        </div>
        <div class="submit-btn">
            <div class="Reject" :class="item.actCode" @click.stop="sub(item)" v-for="(item ,index) in approveBtn" :key="index">
                <span slot="btnContent">{{ item.actName }}</span>
            </div>
        </div>
        <MessageBox ref="submitForm" type="textarea" messageTitle="拒绝原因" pal='请输入原因' :backFun="true" @onOk="submitSettle" messageTxt="请填写拒绝确认订单的原因："></MessageBox>

    </div>
</template>

<script>
import { mapGetters } from "vuex";
import { miceService } from "@/service/miceService.js";
import { UploadPlug } from "smart-filer";
import { SmartStorage } from 'smart-core-util'
import { Toast } from 'mint-ui';
import { formatDate } from '@/core'
const uploadInstance = new UploadPlug({
  host: process.env.VUE_APP_GATEWAY
});
export default {
    name: "MealDetail",
    computed: {
        ...mapGetters(["event", 'memsg', "orderDetail"])
    },
    data() {
        return {
            fromXBH: false,
            result: {},
            waterFilesList: [],
            invoiceFilesList: [],
            style: {
                width: "0.3rem",
                height: "0.3rem",
                borderRadius: "50%",
                background: "rgba(0, 0, 0, 0.1)",
                display: "flex",
                justifyContent: "center",
                alignItems: "center"
            },
            approveBtn:[],
            actionBtn:{}
        };
    },
    mounted() {
        this.fromXBH = SmartStorage.get('isH5')
        this.getMealItemDetail();
        this.getWaterFiles();
        this.getInvoiceFiles();
        if(SmartStorage.get('tenant_code') == 'novartis'){
            this.getQueryTaskActions()
        }
    },
    methods: {
        sub(item){
            this.actionBtn = item
            if(item.actCode == 'Reject'){
                this.$refs.submitForm.openPop(); 
            }else{
                this.approveSuccess(item)
            }
        },
        submitSettle(txt){
            if(!txt.value){
                this.toast(`请填写拒绝原因`);
                return
            }
            let params = {
                taskId: this.orderDetail.taskId,
                actionId: this.actionBtn.actionId,
                TaskData: {
                    Descr: txt.value,
                }
            };
            miceService.PerformTask(params).then(res => {
                if (res.success) {
                    this.toast(`操作成功`);
                    this.$refs.submitForm.openPop(); 
                    this.getMealItemDetail();
                    this.getQueryTaskActions();
                }
            });
            console.log(txt);
        },
        approveSuccess(item) {
            let params = {
                taskId: this.orderDetail.taskId,
                actionId: item.actionId
            };
            miceService.PerformTask(params).then(res => {
                if (res.success) {
                    this.toast(`操作成功`);
                    this.getMealItemDetail();
                    this.getQueryTaskActions();
                }
            });
        },
        getQueryTaskActions() {
            let params = {
                processId: this.orderDetail.itemId,
                taskId: this.orderDetail.taskId || '',
                userId: this.memsg.userId
            };
            miceService.QueryTaskActions(params).then(res => {
                if (res.success) {
                    this.approveBtn = res.content;
                }
            });
        },
        openPdf(pdfUrl){
            this.iJsBridge.openPdfImg(pdfUrl)
            // window.open(pdfUrl)
        },
        openOfd(){
            Toast('暂不支持预览Ofd格式文件');
        },
        getMealItemDetail() {
            const params = {
                itemId: this.orderDetail.itemId
            };
            miceService.getMealItemDetail(params).then(res => {
                if (res && res.success) {
                    this.result = res.content;
                    this.result.itemData = JSON.parse(this.result.itemData)
                }
            });
        },
        getWaterFiles() {
            uploadInstance.queryFile({
                filterData: {
                    filterData: {
                        processId: this.orderDetail.proposalId,
                        relatedId: this.orderDetail.itemId,
                        relatedType: ["settle", "settle_reback", "SupplierSettle","settle_supplement"],
                        catalog: "水单"
                    }
                },
                callback: data => {
                    this.waterFilesList = data.content
                }
            })
        },
        getInvoiceFiles() {
            uploadInstance.queryFile({
                filterData: {
                    filterData: {
                        processId: this.orderDetail.proposalId,
                        itemId: this.orderDetail.itemId,
                        relatedType: ["Invoice", "Invoice_reback","Invoice_supplement"],
                        catalog: "发票"
                    }
                },
                callback: data => {
                    this.invoiceFilesList = data.content

                }
            })
        }
    },
    filters: {
        dateTime: value => {
            if (value) {
                return formatDate(value,'MM-dd HH:mm:ss');
            }
        },
        dateTime2: value => {
            if (value) {
                if(formatDate(value.dtStart,'MM-dd')  ==  formatDate(value.dtEnd,'MM-dd')){
                    return formatDate(value.dtEnd,'HH:mm:ss');
                }else{
                    return formatDate(value.dtEnd,'MM-dd HH:mm:ss');
                }
            }
        }
    }
};
</script>

<style lang="scss">
.merchantBtnRow .icon-pdf2{
    width: 0.8rem;
    height: 0.8rem;
    border-radius: 0.2rem;
}
.invoiceReason{
    word-wrap:break-word;
    text-align: left;
    margin-top: .2rem
}
.submit-btn{
  position: absolute;
  margin: .25rem 2.5%;
  bottom: 0;
  padding: 0;
  width: 95%;
  display: flex;
  justify-content: space-between;
  div{
    width: 45%;
    height: .45rem;
    line-height:.45rem;

  }
  .Reject{
    background: #fff;
    border: 1px solid #ccc;
    border-radius: .03rem;
  }
  .approved{
    background: var(--themeColor);
    border-radius: .03rem;
    color: #fff
  }
}
@import "MealOrderDetail";
</style>