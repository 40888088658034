<template>
    <component :is="MealOrderDetaillDemandx"></component>
</template>
<script>
import { mapGetters } from "vuex";
import MealOrderDetaillDemandx from './MealOrderDetail.vue'
import MealOrderDetaillDemandx2 from './MealOrderDetail2.vue'
export default {
    components: {
        MealOrderDetaillDemandx,
        MealOrderDetaillDemandx2,
    },
    computed: {
        ...mapGetters(['mealRules']),
        MealOrderDetaillDemandx: {
            get: function() {
                var componentId = 'MealOrderDetaillDemandx';
                if(this.mealRules.newUI){
                    componentId = componentId + '2'
                }
                return (componentId);  
            }    
        }
    },
}
</script>